<template>
  <div>
    <div class="card tab">
      <template v-for="(item, index) in tabs">
        <div
          :key="index"
          class="box"
          :style="selectTool === item ? 'color: #2791FF' : ''"
          @click="changeTool(item)"
        >
          {{ item }}
          <div class="span" v-if="selectTool === item"></div>
        </div>
      </template>
    </div>
    <div
      v-if="selectTool === '跟进线索设置'"
      class="content"
      style="
        padding: 30px 0;
        margin-top: 10px;
        border: 1px solid #e8e8e8;
        background: white;
        border-radius: 6px;
      "
    >
      <div style="margin-left: 80px; font-size: 14px; color: #565656">
        <template v-for="(item, index) in clueStageList">
          <div
            style="display: flex; line-height: 40px; margin-bottom: 15px"
            :key="index"
          >
            线索阶段{{ index + 1
            }}<el-input
              style="width: 350px; margin-left: 14px"
              placeholder="请输入"
              v-model="item.title"
              maxlength="8"
              show-word-limit
            />
            <div style="margin: 7px 0 0 15px">
              <img
                src="../../../assets/images/member/add.png"
                @click="handleAddClueStage(index)"
              />
            </div>
            <div style="margin: 7px 0 0 15px">
              <img
                src="../../../assets/images/member/del.png"
                @click="handleDelClueStage(index)"
              />
            </div>
          </div>
        </template>
        <div>提示：线索名称1-8个字以内</div>
      </div>
      <div style="margin: 39px 0 0 0; text-align: center">
        <el-button type="primary" @click="handleSubmit">保存</el-button>
        <span style="margin: 0 20px; color: #909399"
          >{{ clueStageList.length }} / 8</span
        >
        <el-button type="text" @click="handleReset">恢复初始状态</el-button>
      </div>
    </div>
    <div
      v-if="selectTool === '跟进结果设置'"
      class="content"
      style="
        padding: 30px 0;
        margin-top: 10px;
        border: 1px solid #e8e8e8;
        background: white;
        border-radius: 6px;
      "
    >
      <div
        style="
          display: flex;
          margin-left: 80px;
          font-size: 14px;
          color: #565656;
        "
      >
        <div style="margin-right: 15px; line-height: 40px">跟进结果</div>
        <div
          class="resultBox"
          @click="chooseResult(10)"
          :style="
            type === 10
              ? 'background:#e9f8eb;color:#2BBF3F'
              : 'color:#333333;background: #f5f5f5;'
          "
        >
          <img
            src="../../../assets/images/member/stisfied_yes.png"
            v-if="type === 10"
          />
          <img src="../../../assets/images/member/satisfied_no.png" v-else />
          满意
        </div>
        <div
          class="resultBox"
          @click="chooseResult(20)"
          :style="
            type === 20
              ? 'background:#fff7e5;color:#FFAE01'
              : 'color:#333333;background: #f5f5f5;'
          "
        >
          <img
            src="../../../assets/images/member/commonly_yes.png"
            v-if="type === 20"
          />
          <img src="../../../assets/images/member/commonly_no.png" v-else />
          一般
        </div>

        <div
          class="resultBox"
          @click="chooseResult(30)"
          :style="
            type === 30
              ? 'background:#ffeeeb;color:#FD563A'
              : 'color:#333333;background: #f5f5f5;'
          "
        >
          <img
            src="../../../assets/images/member/dissatisfied_yes.png"
            v-if="type === 30"
          />
          <img src="../../../assets/images/member/dissatisfied_no.png" v-else />
          不满意
        </div>
      </div>
      <div
        style="margin: 30px 0 0 80px; font-size: 14px; color: #565656"
        v-if="type === 10"
      >
        <template v-for="(item, index) in followResultType_satisfied">
          <div
            style="display: flex; line-height: 40px; margin-bottom: 15px"
            :key="index"
          >
            第{{ index + 1 }}标签<el-input
              style="width: 350px; margin-left: 14px"
              placeholder="请输入"
              v-model="item.result_title"
              maxlength="8"
              show-word-limit
            />
            <div style="margin: 7px 0 0 15px">
              <img
                src="../../../assets/images/member/add.png"
                @click="handleAddFollowResult(index)"
              />
            </div>
            <div style="margin: 7px 0 0 15px">
              <img
                v-if="followResultType_satisfied.length !== 1"
                src="../../../assets/images/member/del.png"
                @click="handleDelFollowResult(index)"
              />
            </div>
          </div>
        </template>
        <div>提示：标签名称1-8个字内</div>
      </div>
      <div
        style="margin: 30px 0 0 80px; font-size: 14px; color: #565656"
        v-if="type === 20"
      >
        <template v-for="(item, index) in followResultType_commonly">
          <div
            style="display: flex; line-height: 40px; margin-bottom: 15px"
            :key="index"
          >
            第{{ index + 1 }}标签<el-input
              style="width: 350px; margin-left: 14px"
              placeholder="请输入"
              v-model="item.result_title"
              maxlength="8"
              show-word-limit
            />
            <div style="margin: 7px 0 0 15px">
              <img
                src="../../../assets/images/member/add.png"
                @click="handleAddFollowResult(index)"
              />
            </div>
            <div style="margin: 7px 0 0 15px">
              <img
                src="../../../assets/images/member/del.png"
                @click="handleDelFollowResult(index)"
              />
            </div>
          </div>
        </template>
        <div>提示：标签名称1-8个字内</div>
      </div>
      <div
        style="margin: 30px 0 0 80px; font-size: 14px; color: #565656"
        v-if="type === 30"
      >
        <template v-for="(item, index) in followResultType_dissatisfied">
          <div
            style="display: flex; line-height: 40px; margin-bottom: 15px"
            :key="index"
          >
            第{{ index + 1 }}标签<el-input
              style="width: 350px; margin-left: 14px"
              placeholder="请输入"
              v-model="item.result_title"
              maxlength="8"
              show-word-limit
            />
            <div style="margin: 7px 0 0 15px">
              <img
                src="../../../assets/images/member/add.png"
                @click="handleAddFollowResult(index)"
              />
            </div>
            <div style="margin: 7px 0 0 15px">
              <img
                src="../../../assets/images/member/del.png"
                @click="handleDelFollowResult(index)"
              />
            </div>
          </div>
        </template>
        <div>提示：标签名称1-8个字内</div>
      </div>
      <div style="margin: 39px 0 0 0; text-align: center">
        <el-button type="primary" @click="handleSubmitResult">保存</el-button>
        <span style="margin-left: 20px; color: #909399" v-if="type === 10"
          >{{ followResultType_satisfied.length }} / 8</span
        >
        <span style="margin-left: 20px; color: #909399" v-if="type === 20"
          >{{ followResultType_commonly.length }} / 8</span
        >
        <span style="margin-left: 20px; color: #909399" v-if="type === 30"
          >{{ followResultType_dissatisfied.length }} / 8</span
        >
        <el-button type="text" @click="handleResetResult" style="margin-left:10px"
          >恢复初始状态</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import {
  followStepLists,
  followStepUdate,
  followResultLists,
  followResultUdate,
  followReset,
  followResultReset,
} from "@/api/member/memberList/index.js";
export default {
  data() {
    return {
      tabs: ["跟进线索设置", "跟进结果设置"],
      selectTool: "跟进线索设置",
      stageClueList: [],
      type: 10,
      clueStageList: [{ title: "" }],
      followResultList: [],
      followResultType_satisfied: [],
      followResultType_commonly: [],
      followResultType_dissatisfied: [],
    };
  },
  created() {
    this.getFollowStepList();
  },
  methods: {
    changeTool(item) {
      this.selectTool = item;
      switch (item) {
        case "跟进线索设置":
          this.getFollowStepList();
          break;
        case "跟进结果设置":
          this.getFollowResultList();
          break;
      }
    },
    chooseResult(type) {
      this.type = type;
    },
    /*
     * 跟进线索列表
     */
    getFollowStepList() {
      followStepLists().then((res) => {
        if (res.code === 1) {
          this.clueStageList = res.data;
        }
      });
    },
    /*
     * 新增跟进线索
     */
    handleAddClueStage(index) {
      if (this.clueStageList.length < 8) {
        this.clueStageList.splice(index + 1, 0, {
          title: "",
          crm_follow_step_id: 0,
        });
      }
    },
    /*
     * 删除跟进线索
     */
    handleDelClueStage(i) {
      this.clueStageList = this.clueStageList.filter(
        (item, index) => index !== i
      );
    },
    /*
     *  恢复初始状态
     */
    handleReset() {
      followReset().then((res) => {
        if (res.code === 1) {
          this.$message({
            type: "success",
            message: "更新成功",
          });
          this.getFollowStepList();
        }
      });
    },
    handleResetResult() {
      followResultReset().then((res) => {
        if (res.code === 1) {
          this.$message({
            type: "success",
            message: "更新成功",
          });
          this.getFollowResultList();
        }
      });
    },
    /*
     *  保存
     */
    handleSubmit() {
      let isNull = this.clueStageList.some((item) => !item.title);
      if (isNull) {
        this.$message({
          type: "warning",
          message: "请输入线索阶段内容",
        });
      } else {
        let list=this.clueStageList
        const newArr = list.map(item => item.title)
        const arrSet = new Set(newArr)
        // 判断set之后的数组的长度跟set之前的数组是否相等
        if(arrSet.size != list.length){
          this.$message({
            type: "warning",
            message: "跟进线索内容不能重复",
          });
        }else{
          this.clueStageList = this.clueStageList.map((item, index) => {
            item.sort = index + 1;
            return item;
          });
          followStepUdate({ follow_step: this.clueStageList }).then((res) => {
            if (res.code === 1) {
              this.$message({
                type: "success",
                message: "更新成功",
              });
              this.getFollowStepList();
            }
          });
        }

      }
    },
    /*
     * 跟进结果
     */
    getFollowResultList() {
      followResultLists().then((res) => {
        if (res.code === 1) {
          this.followResultList = res.data;
          if (res.data.filter((item) => item.result_type === 10).length !== 0) {
            this.followResultType_satisfied = res.data.filter(
              (item) => item.result_type === 10
            );
          } else {
            this.followResultType_satisfied = [
              {
                result_title: "",
                result_type: 10,
                crm_follow_result_id: 0,
              },
            ];
          }
          if (res.data.filter((item) => item.result_type === 20).length !== 0) {
            this.followResultType_commonly = res.data.filter(
              (item) => item.result_type === 20
            );
          } else {
            this.followResultType_commonly = [
              {
                result_title: "",
                result_type: 20,
                crm_follow_result_id: 0,
              },
            ];
          }
          if (res.data.filter((item) => item.result_type === 30).length !== 0) {
            this.followResultType_dissatisfied = res.data.filter(
              (item) => item.result_type === 30
            );
          } else {
            this.followResultType_dissatisfied = [
              {
                result_title: "",
                result_type: 30,
                crm_follow_result_id: 0,
              },
            ];
          }
        }
      });
    },
    /*
     *  添加跟进结果
     */
    handleAddFollowResult(index) {
      switch (this.type) {
        case 10:
          if (this.followResultType_satisfied.length < 8) {
            this.followResultType_satisfied.splice(index + 1, 0, {
              result_title: "",
              result_type: 10,
              crm_follow_result_id: 0,
            });
          }
          break;
        case 20:
          if (this.followResultType_commonly.length < 8) {
            this.followResultType_commonly.splice(index + 1, 0, {
              result_title: "",
              result_type: 20,
              crm_follow_result_id: 0,
            });
          }
          break;
        case 30:
          if (this.followResultType_dissatisfied.length < 8) {
            this.followResultType_dissatisfied.splice(index + 1, 0, {
              result_title: "",
              result_type: 30,
              crm_follow_result_id: 0,
            });
          }
          break;
      }
    },
    /*
     *  删除跟进结果
     */
    handleDelFollowResult(i) {
      switch (this.type) {
        case 10:
          this.followResultType_satisfied =
            this.followResultType_satisfied.filter(
              (item, index) => index !== i
            );
          break;
        case 20:
          this.followResultType_commonly =
            this.followResultType_commonly.filter((item, index) => index !== i);
          break;
        case 30:
          this.followResultType_dissatisfied =
            this.followResultType_dissatisfied.filter(
              (item, index) => index !== i
            );
          break;
      }
    },
    /*
     *  保存更新跟进结果
     */
    handleSubmitResult() {
      let arr = [];
      arr = arr
        .concat(this.followResultType_satisfied)
        .concat(this.followResultType_commonly)
        .concat(this.followResultType_dissatisfied);
      arr = arr.map((item, index) => {
        item.sort = index + 1;
        return item;
      });
      console.log(arr);
      let isNull = arr.some((item) => item.result_title === "");
      if (isNull) {
        this.$message({
          type: "warning",
          message: "请输入标签内容",
        });
      } else {
        let list=this.followResultType_satisfied;
        const newArr = list.map(item => item.result_title)
        const arrSet = new Set(newArr)
        // 判断set之后的数组的长度跟set之前的数组是否相等
        if(arrSet.size != list.length){
          this.$message({
            type: "warning",
            message: "跟进结果内容不能重复",
          });
          return;
        }
        followResultUdate({
          follow_result: arr.filter((item) => item.result_title !== ""),
        }).then((res) => {
          if (res.code === 1) {
            this.$message({
              type: "success",
              message: "更新成功",
            });
          }
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.tab {
  padding-top: 0px;
  padding-bottom: 0px;
  border-radius: 6px;
  display: flex;
  .box {
    width: 150px;
    text-align: center;
    line-height: 50px;
    font-size: 14px;
    color: #333333;
    .span {
      margin: -3px auto 0 auto;
      width: 20px;
      height: 4px;
      background: #5493f5;
      border-radius: 2px;
    }
  }
  .box:hover {
    cursor: pointer;
  }
}
.resultBox {
  margin-right: 11px;
  padding: 8px 24px;
  background: #f5f5f5;
  border-radius: 6px;
  img {
    width: 24px;
    height: 24px;
    vertical-align: middle;
  }
}
.resultBox:hover {
  cursor: pointer;
}
</style>
